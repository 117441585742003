import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/staff/select-backstage","form",params);
  },
  getDetail(staffId){
    return http.get(`/server/staff/detail/${staffId}`,"form");
  },
  selectDirectors(param){
    return http.get("/server/staff/select/director","form",param);
  },
  removeById(params){
    return http.post("/server/staff/remove","form",params);
  },
  selectListPage(params){
    return http.get("/server/staff/page","form",params);
  },
  layoffById(params){
    return http.post("/server/staff/layoff","form",params);
  },
  unLayOffById(params){
    return http.post("/server/staff/unLayOff","form",params);
  },
  addStaff(params){
    return http.post("/server/staff/save","json",params);
  },
  pageDropDownBySupplierId(params){
    return http.get("/server/drop/down/box/supplier/staff/list","form",params);
  },
  upLoadHeadPic(file) {
    return http.post("/server/staff/upLoad","file",file);
  },
  updatePhone(params){
    return http.post("/server/staff/update/phone",'post',params);
  },
  updatePwd(params){
    return http.post("/server/staff/update/pwd","post",params);
  }

}