import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/delivery-person/page","form",params);
  },
  getDetail(param){
    return http.get("/server/delivery-person/detail","form",param);
  },
  addSender(param){
    return http.post("/server/delivery-person/save","json",param);
  },
  updateSender(param){
    return http.post("/server/delivery-person/update","json",param);
  },
  removeById(params){
    return http.post("/server/delivery-person/remove/batch","form",params);
  }
}