import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/sender',
  component:layout,
  meta:{
    title:'配送员管理'
  },
  children:[
    {
      path:'list',
      name:'Sender',
      component: load('sender/index'),
      meta: {
        title: '配送员列表',
        module:'配送员管理',
        key:'SenderManage',
      }
    }
  ]
}