import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/bid-price',
  component:layout,
  meta:{
    title:'报价管理'
  },
  children:[
    {
      path:'list',
      name:'BidPrice',
      component: load('bidPrice/index'),
      meta: {
        title: '报价单列表',
        module:'报价管理',
        key:'BidPriceManage'
      }
    }
  ]
}