<template>
  <a-breadcrumb class="app-breadcrumb">
    <a-breadcrumb-item>喜来缘管理系统</a-breadcrumb-item>
    <a-breadcrumb-item v-if="$route.meta.module">{{ $route.meta.module }}</a-breadcrumb-item>
    <a-breadcrumb-item>{{$route.meta.title}}</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "bread"
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.ant-breadcrumb {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  line-height: 58px;
  margin-left: 2px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>