import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/purchase',
  component:layout,
  meta:{
    title:'采购管理'
  },
  children:[
    {
      path:'task/list',
      name:'Purchase',
      component: load('purchase/index'),
      meta: {
        title: '采购单列表',
        module:'采购管理',
        key:'PurchaseManage',
      }
    },
    {
      path:'small',
      name:'SmallPurchase',
      component:load('purchase/SmallPurchase'),
      meta: {
        title: '零星采购列表',
        module:'零星采购',
        key:'PurchaseManage',
      }
    }
  ]
}