import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  //用于总后台管理商户
  path:'/merchant',
  component:layout,
  name:'Merchant',
  meta:{
    title:'商户信息管理'
  },
  children:[
    {
      path:'company/list',
      name:'Company',
      component: load('company/index'),
      meta: {
        title: '公司列表',
        module:'商户信息管理',
        key:'MerchantManage',
      }
    },
    {
      path:'supplier/list',
      name:'Supplier',
      component: load('supplier/index'),
      meta: {
        title: '供应商列表',
        module:'商户信息管理',
        key:'MerchantManage',
      }
    }
  ]
}