import Vue from 'vue'
import{
  Button,
  message,
  Layout,
  Menu,
  Icon,
  Form,
  FormModel,
  Select,
  Dropdown,
  Checkbox,
  Input,
  Cascader,
  Tooltip,
  Row,
  AutoComplete,
  Col,
  Upload,
  Modal,
  Radio,
  Breadcrumb,
  Table,
  Divider,
  Tag,
  Popconfirm,
  Pagination,
  ConfigProvider,
  Avatar,
  Popover,
  Descriptions,
  Badge,
  List,
  Collapse,
  DatePicker,
  Switch,
  InputNumber,
  Anchor,
  TreeSelect,
  Tree,
  Drawer,
} from "ant-design-vue";
Vue.use(Button).use(message).use(Layout).use(Menu).use(Icon).use(Form).use(FormModel).use(Select)
  .use(Dropdown).use(Checkbox).use(Input).use(Cascader).use(Tooltip).use(Row).use(Col).use(Modal)
  .use(AutoComplete).use(Upload).use(Radio).use(Breadcrumb).use(Table).use(Divider).use(Tag).use(Popconfirm)
  .use(Pagination).use(ConfigProvider).use(Avatar).use(Popover).use(Descriptions).use(Badge).use(List)
  .use(Collapse).use(DatePicker).use(Switch).use(InputNumber).use(Anchor).use(Tree).use(TreeSelect)
  .use(Drawer);
Vue.prototype.$message =message;