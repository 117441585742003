import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/stock',
  component:layout,
  meta:{
    title:'库存管理'
  },
  children:[
    {
      path:'list',
      name:'Stock',
      component: load('stock/index'),
      meta: {
        title: '入库单列表',
        module:'库存管理',
        key:'StockManage',
      }
    }
  ]
}