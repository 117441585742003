import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import routes from "@/router/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode:"history",
  routes
})
router.beforeEach((to, from, next) => {

  NProgress.start()
  if(to.meta.requireAuth === false){
    next();
  }else {
    const token = localStorage.getItem('token');
    if(token){
      next();
    }else {
      next({
        path:'/login',
        // 保存我们所在的位置，以便以后再来
        query: { redirect: to.fullPath },
        replace:true,
      })
    }
  }
  if (to.matched.some(r => r.meta.title)) {
    document.title = to.meta.title
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})
export default router;

