import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/storage-order/page","form",params);
  },
    add(params){
    return http.post("/server/storage-order/save","json",params);
  },
  update(params){
    return http.post("/server/storage-order/update","json",params);
  },
  removeById(params){
    return http.post("/server/storage-order/remove","form",params);
  },
}