import http from "@/api/http";
export default {
  //分页-采购单列表 （门店 和 公司维度）
  purchaseTaskCompOrResList(params){
    return http.get("/server/small-purchase-task/list","form", params);
  },
  detail(params){
    return http.get("/server/small-purchase-task/detail","form",params);
  },
}
