import http from "@/api/http";
export default {
  //分页-采购单列表 （门店 和 公司维度）
  purchaseOrderTaskCompOrResList(params){
    return http.get("/server/purchase-order-task/list","form", params);
  },
  //分页-采购单列表 （供应商维度）
  purchaseOrderTaskSupplierList(params){
    return http.get("/server/purchase-order-task/supplier/list","form",params);
  },
  createPurchaseOrderTask(params){
    return http.post("/server/purchase-order-task/save","json",params);
  },
  startPurchaseOrderTask(purchaseOrderTaskId) {
    return http.post(`/server/purchase-order-task/begin/${purchaseOrderTaskId}`,"form");
  },
  endPurchaseOrderTask(purchaseOrderTaskId) {
    return http.post(`/server/purchase-order-task/end/${purchaseOrderTaskId}`,"form");
  },
  detail(params){
    return http.get("/server/purchase-order-task/detail","form",params);
  },
}