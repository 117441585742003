import http from "@/api/http";
export default {
  categoryList(params){
    return http.get("/server/category/list","form", params);
  },
  removeById(params){
    return http.post("/server/category/remove","form",params);
  },
  addOrUpdate(params) {
    return http.post("/server/category/submit","json",params);
  },
  categoryDropDownList({current=1,size=20,name=''}){
    return http.get("/server/drop/down/box/category/list","form", {current,size,name});
  }
}