import http from "@/api/http";
export default {
  overviewCount(params){
    return http.get("/server/statistics/admin/total/info","form",params);
  },
  orderDetailPayWayCountTotal(params){
    return http.get("/server/statistics/admin/total/yj/info","form",params);
  },
  purchaseOrderCountListTotal(params){
    return http.get("/server/statistics/admin/total/order/info","form",params);
  },
  purchaseOrderTaskCountTotal(params){
    return http.get("/server/statistics/admin/total/task/info","form",params);
  },
  orderDetailEachSupplierPayWayCountTotal(params){
    return http.get("/server/statistics/admin/total/compYj/info","form",params);
  },
}