import http from "@/api/http";

import company from "@/api/modules/company";
import supplier from "@/api/modules/supplier";
import goods from "@/api/modules/goods";
import staff from "@/api/modules/staff";
import role from "@/api/modules/role";
import category from "@/api/modules/category";
import message from "@/api/modules/message";
import restaurant from "@/api/modules/restaurant";
import purchaseOrderTask from "@/api/modules/purchaseOrderTask";
import smallPurchase from "@/api/modules/smallPurchase";
import smallPurchaseTask from "@/api/modules/smallPurchaseTask";
import smallPurchaseTaskDetail from "@/api/modules/smallPurchaseTaskDetail";
import bidPriceOrder from "@/api/modules/bidPriceOrder";
import purchaseOrderTaskDetail from "@/api/modules/purchaseOrderTaskDetail";
import purchaseOrder from "@/api/modules/purchaseOrder";
import storageOrder from "@/api/modules/storageOrder";
import sender from "@/api/modules/sender";
import returnGoods from "@/api/modules/returnGoods";
import receiveOrder from "@/api/modules/receiveOrder";
import permission from "@/api/modules/permission";
import statistics from "@/api/modules/statistics";
export default {
  company,
  supplier,
  goods,
  staff,
  role,
  category,
  message,
  restaurant,
  purchaseOrderTask,
  bidPriceOrder,
  purchaseOrder,
  purchaseOrderTaskDetail,
  smallPurchase,
  smallPurchaseTask,
  smallPurchaseTaskDetail,
  sender,
  returnGoods,
  receiveOrder,
  storageOrder,
  permission,
  statistics,
  common:{
    login(params = {mode: 0, openId: '', password: '', phone: '', smsCode: '', userType: 0}) {
      return http.post("/auth/login",'json', params);
    },
    logout(){
      return http.post("/auth/logout",'json',{});
    },
    sendCode({phone='',userType=''}) {
      return http.post("/auth/sms",'form',{phone,userType});
    },
    changePwd(params={code: "", password: "", phone: "", repeatPwd: "", userType: 0}){
      return http.post("/server/staff/forget/pwd","json",params);
    },
    upload(params){
      return http.post("/auth/upLoad","file",params);
    },
  }
}
