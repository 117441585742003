import http from "@/api/http";
export default {
  goodsDropDownList(params){
    return http.get("/server/drop/down/box/product/list","form", params);
  },
  goodsList(params){
    return http.get("/server/product/page","form",params);
  },
  removeById(params){
    return http.post("/server/product/remove","form",params);
  },
  addGoods(params){
    return http.post("/server/product/save","json",params);
  },
  editGoods(params){
    return http.post("/server/product/update","json",params);
  },
  goodsDetail(params){
    return http.get("/server/product/detail","form",params);
  }
}