import http from "@/api/http";
export default {
  restaurantDropDownList({current=1,size=20,name=''}){
    return http.get("/server/drop/down/box/restaurant/list","form", {current,size,name});
  },
  resBindSupplierDropDownList(params){
    return http.get("/server/drop/down/box/restaurant/supplier/list","form",params);
  },
  selectByCondition(params){
    return http.get("/server/restaurant/page","form",params);
  },
  addRes(params){
    return http.post("/server/restaurant/save","json",params);
  },
  removeById(params){
    return http.post("/server/restaurant/remove","form",params);
  },
  updateById(params){
    return http.post("/server/restaurant/update","json",params);
  },
  bindSupplier(params){
    return http.post("/server/company/bind-supplier","form",params);
  },
  unBindSupplier(params){
    return http.post("/server/company/unbind-supplier","form",params);
  },
}