import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const store = new Vuex.Store({
  state:{
    token:null,
    userInfo: null,
    roleInfo:null,
    categoryInfo:null,
    goodsInfo:null,
    storageInfo:null,
    companyInfo:null,
    supplierInfo:null,
    restaurantInfo:null,
    permissionInfo:null,
  },
  mutations:{
    setToken (state, token) {
      state.token = token
      if(!token){
        localStorage.removeItem('token');
        return;
      }
      localStorage.setItem('token',token);
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo;
      if(!userInfo){
        localStorage.removeItem('userInfo');
        return;
      }
      localStorage.setItem('userInfo',JSON.stringify(userInfo));
    },
    setRoleInfo(state,roleInfo){
      state.roleInfo = roleInfo;
    },
    setCategoryInfo(state,categoryInfo){
      state.categoryInfo = categoryInfo;
    },
    setGoodsInfo(state,goodsInfo){
      state.goodsInfo = goodsInfo;
    },
    setStorageInfo(state,storageInfo){
      state.storageInfo = storageInfo;
    },
    setCompanyInfo(state,companyInfo){
      state.companyInfo = companyInfo;
    },
    setSupplierInfo(state,supplierInfo){
      state.supplierInfo = supplierInfo;
    },
    setRestaurantInfo(state,restaurantInfo){
      state.supplierInfo = restaurantInfo;
    },
    setPermissionInfo(state,permissions){
      state.permissionInfo = permissions
    },
  },
  getters: {
    userInfo: (state)=>{
      if(!state.userInfo){
        state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      }
      return state.userInfo;
    },
    roleInfo:(state)=>{
      return state.roleInfo;
    },
    categoryInfo:(state)=>{
      return state.categoryInfo;
    },
    goodsInfo:(state)=>{
      return state.goodsInfo;
    },
    storageInfo:(state)=>{
      return state.storageInfo;
    },
    companyInfo:(state)=>{
      return state.companyInfo;
    },
    supplierInfo:(state)=>{
      return state.supplierInfo;
    },
    restaurantInfo:(state)=>{
      return state.restaurantInfo;
    },
    permissionInfo:(state)=>{
      return state.permissionInfo;
    }
   },
})

export default store;