import http from "@/api/http";
export default {
  selectMsgList(params){
    return http.get("/server/message-notice/page","form",params);
  },
  readMsg(param){
    return http.get("/server/message-notice/read","form",param);
  },
  removeMsgByIds(params){
    return http.post("/server/message-notice/remove","form",params);
  },
  msgDetail(params){
    return http.get("/server/message-notice/detail","form",params);
  },
}