import http from "@/api/http";
export default {
  permissionTree(){
    return http.get("/server/permission/list/tree","form");
  },
  getRolePermissions(roleId){
    return http.post(`/server/role-permission/role/permission/list/${roleId}`,"form")
  },
  updateRolePermissions(params){
    return http.post("/server/role-permission/update","form",params);
  },
}