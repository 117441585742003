<template>
  <div>
    <a-layout class="layout-main">
      <a-layout-sider class="left-menu" v-model="collapsed" :trigger="null" collapsible>
        <div class="logo">{{ collapsed ? '' : '喜来缘管理系统' }}</div>
        <a-menu theme="dark" mode="inline"
                :defaultOpenKeys="openKeys" :openKeys.sync="openKeys"
                :default-selected-keys="selectedKeys" v-model="selectedKeys">
          <a-menu-item v-if="permissions.indexOf('DataCenter')!==-1" key="DataCenter">
            <router-link to="/datacenter" tag="div">
              <a-icon type="radar-chart"/>
              <span>企业平台</span>
            </router-link>
          </a-menu-item>
          <a-sub-menu v-if="permissions.indexOf('PurchaseManage')!==-1&&status===1" key="PurchaseManage">
            <span slot="title"><a-icon type="snippets"/><span>采购管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Purchase')!==-1&&status===1" key="Purchase">
              <router-link to="/purchase/task/list" tag="div">采购单列表</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('SmallPurchase')!==-1" key="SmallPurchase">
              <router-link to="/purchase/small" tag="div">零星采购</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('BidPriceManage')!==-1&&status===1" key="BidPriceManage">
            <span slot="title"><a-icon type="ordered-list"/><span>报价管理</span></span>
            <a-menu-item v-if="permissions.indexOf('BidPrice')!==-1&&status===1" key="BidPrice">
              <router-link to="/bid-price/list" tag="div">报价单列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('OrderManage')!==-1&&status===1" key="OrderManage">
            <span slot="title"><a-icon type="container"/><span>订单管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Order')!==-1&&status===1" key="Order">
              <router-link to="/order/list" tag="div">订单列表</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Receive')!==-1&&status===1" key="Receive">
              <router-link to="/receive/order/list" tag="div">验收单列表</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Return')!==-1&&status===1" key="Return">
              <router-link to="/receive/order/return/list" tag="div">退货列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('SupplierBindManage')!==-1&&status===1" key="SupplierBindManage">
            <span slot="title"><a-icon type="car"/><span>供应商管理</span></span>
            <a-menu-item v-if="permissions.indexOf('SupplierBind')!==-1&&status===1" key="SupplierBind">
              <router-link to="/supplier/bind/list" tag="div">关联供应商列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('ResStaffManage')!==-1&&status===1" key="ResStaffManage">
            <span slot="title"><a-icon type="team"/><span>门店员工管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Restaurant')!==-1&&status===1" key="Restaurant">
              <router-link to="/restaurant/list" tag="div">门店列表</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Staff')!==-1&&status===1" key="Staff">
              <router-link to="/staff/list" tag="div">员工列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('SenderManage')!==-1&&status===1" key="SenderManage">
            <span slot="title"><a-icon type="solution"/><span>配送员管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Sender')!==-1&&status===1" key="Sender">
              <router-link to="/sender/list" tag="div">配送员列表</router-link>
            </a-menu-item>
          </a-sub-menu>

          <!--总后台-->
          <a-sub-menu v-if="permissions.indexOf('MerchantManage')!==-1" key="MerchantManage">
            <span slot="title"><a-icon type="appstore"/><span>商户信息管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Company')!==-1" key="Company">
              <router-link to="/merchant/company/list" tag="div">公司列表</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Supplier')!==-1" key="Supplier">
              <router-link to="/merchant/supplier/list" tag="div">供应商列表</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('GoodsManage')!==-1&&status===1" key="GoodsManage">
            <span slot="title"><a-icon type="shopping"/><span>商品管理</span></span>
            <a-menu-item v-if="permissions.indexOf('Goods')!==-1&&status===1" key="Goods">
              <router-link to="/goods/basic/list" tag="div">商品信息</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Category')!==-1&&status===1" key="Category">
              <router-link to="/goods/category/list" tag="div">商品分类</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Storage')!==-1&&status===1" key="Storage">
              <router-link to="/goods/storage/list" tag="div">商品入库</router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-sub-menu v-if="permissions.indexOf('RolePermissionManage')!==-1" key="RolePermissionManage">
            <span slot="title"><a-icon type="idcard"/><span>用户权限管理</span></span>
            <a-menu-item v-if="permissions.indexOf('UserList')!==-1" key="UserList">
              <router-link to="/permission/user/list" tag="div">
                <span>用户管理</span>
              </router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Role')!==-1" key="Role">
              <router-link to="/role/list" tag="div">角色管理</router-link>
            </a-menu-item>
            <a-menu-item v-if="permissions.indexOf('Permission')!==-1" key="Permission">
              <router-link to="/permission/list" tag="div">
                <span>权限管理</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <!--共有导航-->
          <a-menu-item v-if="permissions.indexOf('Message')!==-1" key="Message">
            <router-link to="/account/message" tag="div">
              <a-icon type="message"/>
              <span>消息通知</span>
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout class="layout-right">
        <a-layout-header class="layout-header">
          <a-icon
              class="hamburger-container"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
          />
          <bread class="breadcrumb-container"></bread>
          <div class="right-menu">
          <span style="font-size: 16px">
            {{ restaurantName ? restaurantName : companyName ? companyName : '欢迎！!' }}
          </span>
            <a-badge :count="unReadCount">
              <a-avatar :src="this.headPicImage?this.headPicImage:require('../assets/images/user/defaultHead.jpeg')"
                        @click="goToMsg"/>
            </a-badge>
            <a-dropdown style="margin-right: 20px">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{ this.userName }}
                <a-icon type="down"/>
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="showPersonalInfo">
                    <a-icon type="info-circle"/>
                    个人中心
                  </a>
                </a-menu-item>
                <a-menu-item v-if="status==0&&(roleIds.indexOf('2')!==-1||roleIds.indexOf('6')!==-1)">
                  <a @click="showCheckToast">
                    <a-icon type="check-circle"/>
                    审核状态
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="logout">
                    <a-icon type="logout"/>
                    注销登录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-layout-header>
        <a-layout-content class="layout-center">
          <router-view></router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>

    <a-modal title="审核状态"
             :visible="isClickVisible"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="500px">

    </a-modal>
  </div>
</template>

<script>
import Bread from "@/components/bread";
import {Modal} from "ant-design-vue";
export default {
  name: "layout",
  components: {Bread},
  data() {
    return {
      permissions:this.$store.getters.userInfo.permissions,
      status:this.$store.getters.userInfo.companyStatus,
      collapsed: false,
      selectedKeys:[],
      openKeys:[],
      userName:'我的',
      headPicImage:'',
      companyName:'',
      restaurantName:'',
      roleIds:'',
      isMouseOver:false,
      unReadCount:0,
      personalRoute:false,
      isClickVisible:false,
    };
  },
  created() {
    if(this.$route.meta&&this.$route.meta.key){
      this.openKeys = [this.$route.meta.key];
    }
    this.selectedKeys = [this.$route.name];
    const queryParam ={
      isRead: undefined,
      targetType:undefined,
    };
    this.setUserInfo();
    const {staffType,restaurantId} = this.$store.getters.userInfo;
    switch (staffType){
      case 0:
        queryParam.targetType = 1;
        break;
      case 1:
        queryParam.targetType = 2;
        break;
      case 2:
        queryParam.targetType = 0;
        break;
    }
    if(restaurantId>0){
     queryParam.targetType = 3;
    }
    let query = {
      current:1,
      size:-1,
      isRead:false,
      targetType:queryParam.targetType,
    }
    this.$api.message.selectMsgList(query).then(res=>{
      if(res.code===200){
        const dataSource = res.data.records;
        if(dataSource.length>0){
          this.unReadCount = dataSource.length;
        }
      }
    });
  },
  methods:{
     logout(){
      //TODO 清除登录信息
      this.$api.common.logout().then(res=>{
        if(res.code===200){
          this.$message.success(res.msg);
          this.$store.commit('setToken',null);
          this.$store.commit('setUserInfo',null);
          this.$router.push("/login");
        }
      })
    },
    setUserInfo(){
       const userInfo = this.$store.getters.userInfo;
       //console.log(userInfo);
       //console.log(userInfo.name);
       this.userName = userInfo.name||'我的';
       this.headPicImage = userInfo.headPicImage;
       this.companyName = userInfo.companyName;
       this.restaurantName = userInfo.restaurantName;
       this.roleIds = userInfo.roleIds;
    },
    goToMsg(){
       const currentPath = this.$router.history.current.path;
       let targetPath = '/account/message';
       if(currentPath!==targetPath){
         this.$router.push(targetPath);
       }
    },
    showPersonalInfo(){
      //TODO 查询个人信息
      this.personalRoute = true;
      const currentPath = this.$route.path;
      const remotePath = '/account/personal/info';
      if (currentPath !== remotePath)
        this.$router.push(remotePath);
    },
    showCheckToast(){
       const that = this;
       const status = this.$store.getters.userInfo.companyStatus;
       const checkRemark = this.$store.getters.userInfo.companyCheckRemark;
       switch (status){
         case 0:
           Modal.info({
             title:"未审核",
           })
           return;
         case 1:
           Modal.success({
             title: '审核成功',
             okText:'跳转登录',
             onOk(){
               that.$router.replace("/login");
             }
           })
           return;
         case 2:
           Modal.error({
             title:'审核驳回',
             content:'驳回原因：'+checkRemark,
           })
           return;
       }

      // this.isClickVisible = true;
    },
    handleOk(){
       this.handleCancel();
    },
    handleCancel(){
       this.isClickVisible = false;
    }
  },
  watch:{
    '$route':function(to){
      //console.log("去",to,"源",from);
      if(!this.personalRoute){
        this.selectedKeys = [to.name];
        this.openKeys = [this.$route.meta.key];
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.layout-main{
  height: 100vh;
  min-height: 100%;
  font-family: 楷体;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }
  .layout-header{
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    padding: 0;
    background: #f7f7f7;

    height: 50px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 1px 4px rgba(0,21,41,.08);

    .hamburger-container {
      float: left;
      font-size: 18px;
      line-height: 64px;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: #1890ff;
      }
    }
    .breadcrumb-container {
      float: left;

    }
    .right-menu{
      float: right;
      height: 100%;
      line-height: 58px;
      font-family: 楷体;
    }
  }
  .left-menu{
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar{
      width: 6px;
      height: 6px;
    }
    //滚动区域背景
    &::-webkit-scrollbar-track-piece{
      background-color: #002140;
      -webkit-border-radius: 6px;
    }
    //竖向滚动条
    &::-webkit-scrollbar-thumb:vertical{
      height: 5px;
      background-color: #002140;
      -webkit-border-radius: 6px;
    }
  }
  .logo {
    height: 32px;
    //background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    color: #dfe1e2;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  .layout-right{
    height: 100vh;
    .layout-center {
      margin: 10px 10px;
      padding: 20px;
      background: #fff;
      min-height: 280px;
      border-radius: 10px;
      overflow-y: scroll;
    }
  }
}


</style>