import http from "@/api/http";
export default {
  register(params){
    return http.post("/server/supplier/register","json",params);
  },
  selectByName(params){
    return http.get("/server/supplier/select_by_name","form", params)
  },
  selectStaffCount(){
    return http.post("/server/supplier/select/staff/count")
  },
  supplier4Admin(params){
    return http.get("/server/supplier/admin/supplier/list","form",params)
  },
  detail(params){
    return http.get("/server/supplier/detail","form",params);
  },
  check(params){
    return http.post("/server/supplier/check","json",params);
  },
  selectListOfComp(params){
    return http.get("/server/supplier/page","form",params);
  },
  supplierBindResDropDownList(params){
    return http.get("/server/drop/down/box/supplier/restaurant/list","form",params);
  },
  supplierBindCompanyDropDownList(params){
    return http.get("/server/drop/down/box/supplier/company/list","form",params);
  },
  update(params){
    return http.post("/server/supplier/submit","json",params);
  }
}