import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/returns/list","form",params);
  },
  selectByReceiveOrderId(receiveOrderId){
    return http.get(`/server/returns/list/${receiveOrderId}`,"form");
  },
  addReturn(params){
    return http.post("/server/returns/save","json",params);
  },
  updateReturn(params){
    return http.post("/server/returns/update-status","form",params);
  },
}