import http from "@/api/http";
export default {
  pageList(params){
    return http.get("/server/bid-price-order/list","form", params);
  },
  detail(params){
    return http.get("/server/bid-price-order/detail","form", params);
  },
  createBidPriceOrder(params){
    return http.post("/server/bid-price-order/save","json",params);
  },
  //重新报价
  repeatBidPrice(oldBidId,params) {
    return http.post(`/server/bid-price-order/repeat-bid/${oldBidId}`,"json",params);
  },
  bidSuccess(params){
    return http.post("/server/bid-price-order/bid-success","form",params);
  },
  bidFailed(params) {
    return http.post("/server/bid-price-order/bid-failed","json",params);
  },
  updatePurchaseNum(params){
    return http.post("/server/bid-price-order/update/purchase/num","form",params);
  },
}