import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/restaurant',
  component:layout,
  meta:{
    title:'门店管理'
  },
  children:[
    {
      path:'list',
      name:'Restaurant',
      component: load('restaurant/index'),
      meta: {
        title: '门店列表',
        module:'门店管理',
        key:'ResStaffManage',
      }
    }
  ]
}