import fetch from "@/api/request";
import {
  AXIOS_DEFAULTS_RESPONSE_TYPE
} from "@/config/axios.config";

const http = {
  get(url,contentType,params){
    return fetch(url,contentType,params,'get',AXIOS_DEFAULTS_RESPONSE_TYPE);
  },
  post(url,contentType,params){
    return fetch(url,contentType,params,'post',AXIOS_DEFAULTS_RESPONSE_TYPE)
  },
  put(url,contentType,params){
    return fetch(url,contentType,params,'put',AXIOS_DEFAULTS_RESPONSE_TYPE)
  },
  delete(url,contentType,params){
    return fetch(url,contentType,params,'delete',AXIOS_DEFAULTS_RESPONSE_TYPE)
  }
}

export default http;

