import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import echarts from 'echarts';

import api from './api'
import '@/assets/styles/main.scss'
import "./config/antDesignConfig"
import 'ant-design-vue/lib/message/style/css'
import 'ant-design-vue/lib/menu/style/css'
import 'ant-design-vue/lib/layout/style/css'
import 'ant-design-vue/lib/icon/style/css'
import 'ant-design-vue/lib/dropdown/style/css'
import 'ant-design-vue/lib/button/style/css'
import 'ant-design-vue/lib/breadcrumb/style/css'
import 'ant-design-vue/lib/cascader/style/css'
import 'ant-design-vue/lib/tooltip/style/css'
import 'ant-design-vue/lib/auto-complete/style/css'
import 'ant-design-vue/lib/row/style/css'
import 'ant-design-vue/lib/col/style/css'
import 'ant-design-vue/lib/upload/style/css'
import 'ant-design-vue/lib/modal/style/css'
import 'ant-design-vue/lib/radio/style/css'
import 'ant-design-vue/lib/form/style/css'
import 'ant-design-vue/lib/form-model/style/css'
import 'ant-design-vue/lib/button/style/css'
import 'ant-design-vue/lib/select/style/css'
import 'ant-design-vue/lib/checkbox/style/css'
import 'ant-design-vue/lib/input/style/css'
import 'ant-design-vue/lib/table/style/css'
import 'ant-design-vue/lib/divider/style/css'
import 'ant-design-vue/lib/tag/style/css'
import 'ant-design-vue/lib/popconfirm/style/css'
import 'ant-design-vue/lib/pagination/style/css'
import 'ant-design-vue/lib/avatar/style/css'
import 'ant-design-vue/lib/popover/style/css'
import 'ant-design-vue/lib/descriptions/style/css'
import 'ant-design-vue/lib/badge/style/css'
import 'ant-design-vue/lib/list/style/css'
import 'ant-design-vue/lib/collapse/style/css'
import 'ant-design-vue/lib/date-picker/style/css'
import 'ant-design-vue/lib/switch/style/css'
import 'ant-design-vue/lib/input-number/style/css'
import 'ant-design-vue/lib/anchor/style/css'
import 'ant-design-vue/lib/tree-select/style/css'
import 'ant-design-vue/lib/tree/style/css'
import 'ant-design-vue/lib/drawer/style/css'
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts;
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
