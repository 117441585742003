import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/account',
  component:layout,
  meta:{
    title:'用户中心'
  },
  children:[
    {
      path:'message',
      name:'Message',
      component: load('home/message'),
      meta: {
        title: '消息列表',
        module:'消息通知',
        key:'Message',
      }
    },
    {
      path:'personal/info',
      name:'PersonalDetail',
      component: load('home/personal-info'),
      meta: {
        title: '个人信息',
      }
    }
  ]
}