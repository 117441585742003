import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/role',
  component:layout,
  meta:{
    title:'角色管理'
  },
  children:[
    {
      path:'list',
      name:'Role',
      component: load('role/index'),
      meta: {
        title: '角色列表',
        module:'角色管理',
        key:'RolePermissionManage',
      }
    },
    {
      path:'detail',
      name:'RoleDetail',
      component: load('role/detail'),
      meta: {
        title: '角色详情',
        module:'角色管理',
      }
    },
    {
      path:'add',
      component: load('role/add'),
      meta: {
        title: '添加角色',
        module:'角色管理',
      }
    },
    {
      path:'permission',
      component: load('role/role-permission'),
      meta: {
        title: '角色权限分配',
        module:'角色管理',
      }
    },
  ]
}