import http from "@/api/http";
export default {
  getDetail(params){
    return http.get("/server/purchase-order-task-detail/detail","form",params);
  },
  addPurchaseOrderDetail(params){
    return http.post("/server/purchase-order-task-detail/save","json",params);
  },
  addPurchaseOrderDetailList(params){
    return http.post("/server/purchase-order-task-detail/save/list","json",params);
  },
  updatePurchaseOrderDetail(params){
    return http.post("/server/purchase-order-task-detail/update","json",params);
  },
  updateList(params){
    return http.post("/server/purchase-order-task-detail/update/list","json",params);
  },
  saveOrUpdateList(params){
    return http.post("/server/purchase-order-task-detail/saveOrUpdate/list","json",params);
  },
  removeList(params){
    return http.post("/server/purchase-order-task-detail/remove","form",params);
  }
}