import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/receive/order',
  component:layout,

  meta:{
    title:'验收单管理'
  },
  children:[
    {
      path:'list',
      name:'Receive',
      component: load('receive/index'),
      meta: {
        title: '验收单列表',
        module:'验收单管理',
        key:'OrderManage',
      }
    },
    {
      path:'return/list',
      name:'Return',
      component: load('reback/index'),
      meta: {
        title: '退货单列表',
        module:'验收单管理',
        key:'OrderManage',
      }
    }
  ]
}