import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/staff',
  component:layout,
  meta:{
    title:'员工管理'
  },
  children:[
    {
      path:'list',
      name:'Staff',
      component: load('staff/index'),
      meta: {
        title: '员工列表',
        module:'员工管理',
        key:'ResStaffManage',
      }
    },
  ]
}