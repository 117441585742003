import http from "@/api/http";
export default {
  //分页-采购单列表
  purchaseOrderList(params){
    return http.get("/server/purchase-order/page","form", params);
  },
  purchaseOrderSenderList(params){
    return http.get("/server/purchase-order/list/purchaseOrderId","form",params);
  },
  ensureOrder(orderId){
    return http.post(`/server/purchase-order/receive-order/${orderId}`,"form");
  },
  cancelOrder(orderId){
    return http.post(`/server/purchase-order/cancel-order/${orderId}`,"form");
  },
  detail(params){
    return http.get("/server/purchase-order/detail","form",params);
  },
  checkOrder(params){
    return http.post("/server/purchase-order/check-order","json",params);
  },
  orderSenderList(orderId){
    return http.get(`/server/purchase-order/list/sender/${orderId}`,"form");
  },
  orderGoodsInfo(orderId){
    return http.get(`/server/purchase-order/product/basic/info/${orderId}`,"form");
  }
}