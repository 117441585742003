import layout from "@/components/layout";
import purchase from "./modules/purchase";
import bidPrice from "@/router/modules/bidPrice";
import order from "@/router/modules/order";
import receive from "@/router/modules/receive";
import goods from "@/router/modules/goods";
import home from "@/router/modules/home";
import merchant from "@/router/modules/merchant";
import restaurant from "@/router/modules/restaurant";
import staff from "@/router/modules/staff";
import sender from "@/router/modules/sender";
import role from "@/router/modules/role";
import supplier from "@/router/modules/supplier";
import stock from "@/router/modules/stock";
import permission from "@/router/modules/permission";


function load(comp){
  return resolve =>require([`../views/${comp}.vue`],resolve);
}

export default[
  {
    path:'/',
    redirect:'/account/personal/info',
    component:layout,
    children:[
      {
        path:'datacenter',
        name:'DataCenter',
        component: load('home/index'),
        meta: {
          title: '数据中心',
          module:'企业平台',
          key:'DataCenter',
        }
      },
    ]
  },

  {
    path:'/login',
    name:'Login',
    component:load('login'),
    meta: {
      title:'登录',
      requireAuth: false,
      noCache: true,
    }
  },
  {
    path: '/403',
    name:'403',
    component: load('403'),
    meta: {
      title: '403',
      requireAuth: false,
      noCache: true,
    }
  },
  {
    path: '/404',
    name:'404',
    component: load('404'),
    meta: {
      title: '404',
      requireAuth: false,
      noCache: true,
    }
  },
  {
    path: '/error',
    name:'Error',
    component: load('error'),
    meta: {
      title:'error',
      requireAuth: false,
      noCache: true,
    }
  },
  {
    path:'/register',
    name:"Register",
    component:load('register'),
    meta: {
      title: '注册',
      requireAuth: false,
      noCache: true,
    }
  },
  bidPrice,
  order,
  receive,
  goods,
  home,
  merchant,
  restaurant,
  staff,
  sender,
  role,
  stock,
  supplier,
  purchase,
  permission,
  {
    path: '*',
    component: load('404'),
    meta: {
      title: '404',
      requireAuth: false,
      noCache: true,
    }
    // path: '*',
    // component: layout,
    // hidden: true,
    // children: [
    //   {
    //     path: '*',
    //     component: load('404'),
    //     meta: {
    //       title: '404',
    //       requireAuth: false,
    //       noCache: true,
    //     }
    //   }
    // ]
  },
]