import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/goods',
  component:layout,
  meta:{
    title:'商品管理'
  },
  children:[
    {
      path:'basic/list',
      name:'Goods',
      component: load('product/index'),
      meta: {
        title: '商品列表',
        module:'商品管理',
        key:'GoodsManage',
      }
    },
    {
      path:'category/list',
      name:'Category',
      component: load('product/category'),
      meta: {
        title: '商品分类',
        module:'商品管理',
        key:'GoodsManage',
      }
    },{
      path:'storage/list',
      name:'Storage',
      component: load('product/storage'),
      meta: {
        title: '商品入库',
        module:'商品管理',
        key:'GoodsManage',
      }
    },
  ]
}