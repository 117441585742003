import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/order',
  component:layout,
  meta:{
    title:'订单管理'
  },
  children:[
    {
      path:'list',
      name:'Order',
      component: load('order/index'),
      meta: {
        title: '订单列表',
        module:'订单管理',
        key:'OrderManage',
      }
    }
  ]
}