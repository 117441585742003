<template>
  <a-config-provider :locale="locale">
    <router-view  v-if="isRouterAlive"/>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  name:'app',
  provide(){
    return{
      reload:this.reload
    }
  },

  data(){
    return{
      isRouterAlive:true,
      locale: zhCN,
    }
  },

  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  },
}
</script>

<style lang="scss">
html,body{
 font-family: 楷体, serif;
}
</style>
