import axios from "axios";
import router from "@/router";
import {AXIOS_DEFAULTS_URL,AXIOS_DEFAULTS_PARAMS,AXIOS_DEFAULTS_METHOD,
  AXIOS_DEFAULTS_TIMEOUT,AXIOS_DEFAULTS_CONTENT_TYPE,AXIOS_DEFAULTS_RESPONSE_TYPE}
  from "../config/axios.config"
import {message} from "ant-design-vue"
import {permitURL} from "@/api/permitURL";

const request = axios.create({
  baseURL:AXIOS_DEFAULTS_URL,
  timeout:AXIOS_DEFAULTS_TIMEOUT,
})

request.interceptors.request.use(req=>{
  let url = req.url;
  const token = localStorage.getItem("token")||"";
  if(permitURL.indexOf(url)===-1){
    if(token){
      req.headers.Authorization = token;
    }
  }
  return req;

},error => {
  Promise.reject(error)
})

request.interceptors.response.use(response=>{
  const data = response.data;
  // if(data.success && data.code===200){
  //     message.success(data.msg,3)
  // }else if(data.success===false){
  //   message.error(data.msg,3)
  // }else {
  //   message.error(data.msg,3);
  // }
  return data;
},error => {
  if(error&&error.response){
// 1.公共错误处理
    switch (error.response.status) {
      case 400:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '业务异常'
        }
        break
      case 401:
        if (error.response.data.msg) {
          error.message = error.response.data.msg;
          if (error.response.data.msg.indexOf("Full") !== -1) {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            axios({
              url:"/auth/generate/logout",
              method:'post',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              params:{
                account:userInfo.phone,
                userType:userInfo.staffType,
              }
            })
            localStorage.removeItem("userInfo");
            localStorage.removeItem('token');
            error.message = '当前登录时效已过，请重新登录'
          }
        } else {
          error.message = '当前登录时效已过，请重新登录'
        }
        router.push({
          path: '/login'
        })
        break
      case 403:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '权限不足，拒绝访问'
        }
        break
      case 404:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '404 没找到请求'
        }
        break
      case 405:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '不支持当前请求方法'
        }
        break
      case 408:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '请求超时'
        }
        break
      case 500:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '服务器异常'
        }
        break
      case 501:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '网络未实现'
        }
        break
      case 502:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '网络未连接'
        }
        break
      case 503:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = '服务不可用'
        }
        break
      case 505:
        if(error.response.data.msg){
          error.message = error.response.data.msg;
        }else{
          error.message = 'http版本不支持该请求'
        }
        break
      default:
        error.message = `连接有误${error.response.status}`
    }
    const msgKey = "Error401";
    if(sessionStorage.getItem(msgKey)!=='true'){
      sessionStorage.setItem(msgKey,'true');
      message.error({
        content:error.message,
        onClose:()=>{
          sessionStorage.removeItem(msgKey);
        }
      })
    }
  }else{
    if (error.message.includes('timeout')) {
      message.error('请求超时，请稍后刷新重试。')
    }
    error.message = "连接服务器失败";
  }
  return Promise.resolve(error.response);
})
function fetch(url = AXIOS_DEFAULTS_URL,
               contentType = AXIOS_DEFAULTS_CONTENT_TYPE,
               params = AXIOS_DEFAULTS_PARAMS,
               method = AXIOS_DEFAULTS_METHOD,
               responseType = AXIOS_DEFAULTS_RESPONSE_TYPE,
               timeout = AXIOS_DEFAULTS_TIMEOUT){
  switch (contentType){
    case 'form':
      contentType = 'application/x-www-form-urlencoded';
      break;
    case 'json':
      contentType = 'application/json;charset=utf-8';
      break;
    case 'file':
      contentType = 'multipart/form-data';
      break;
    default:
      contentType = 'application/json'
  }
  const query = Object.keys(params).map(k => {
    const val = params[k] === undefined ? '' : params[k]
    return `${k}=${val}`
  })
  let qs = query.join('&')
  if (method.toLowerCase() === 'get' && query.length > 0) {
    url += (url.indexOf('?') < 0 ? '?' : '&') + qs
  }
  if (contentType !== 'application/x-www-form-urlencoded' && method !== 'get') {
    qs = params
  }
  const config = {
    method: method,
    url: url,
    data: qs,
    headers: {
      'Content-Type': contentType
    },
    responseType,
    timeout
  }
  return request(config);
}

export default fetch;



