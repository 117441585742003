import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/role/page","form",params);
  },
  addOrUpdate(param){
    return http.post("/server/role/submit","json",param);
  },
  removeById(params){
    return http.post("/server/role/remove","form",params);
  },
  selectByStaffId(staffId){
    return http.post(`/server/role/select/user/roles/${staffId}`,"form")
  },
  addUserRoleList(params){
    return http.post("/server/role/save/user/roles","form",params);
  }
}
