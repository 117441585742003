import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/permission',
  component:layout,

  meta:{
    title:'权限管理'
  },
  children:[
    {
      path:'list',
      name:'Permission',
      component: load('permission/index'),
      meta: {
        title: '权限列表',
        module:'权限管理',
        key:'RolePermissionManage',
      }
    },
    {
      path:'user/list',
      name:'UserList',
      component: load('staff/userIndex'),
      meta: {
        title: '用户列表',
        module:'权限管理',
        key:'RolePermissionManage',
      }
    },
  ]
}