import http from "@/api/http";
export default {
  register(params){
    return http.post("/server/company/register","json",params);
  },
  selectByName(params){
    return http.get("/server/company/select_by_name","form", params)
  },
  selectStaffCount(){
    return http.post("/server/company/select/staff/count")
  },
  company4AdminList(params){
    return http.get("/server/company/page","form",params)
  },
  detail(params){
    return http.get("/server/company/detail","form",params);
  },
  check(params){
    return http.post("/server/company/check","json",params);
  },
  companyBindSupplierDropDownList(params){
    return http.get("/server/drop/down/box/company/supplier/list","form",params);
  },
  update(params){
    return http.post("/server/company/submit","json",params);
  }
}