import http from "@/api/http";
export default {
  selectByCondition(params){
    return http.get("/server/receive-order/page","form",params);
  },
    detail(receiveId){
    return http.get(`/server/receive-order/detail/${receiveId}`,"form");
  },
  detailByPurchaseOrderId(purchaseOrderId){
    return http.get(`/server/receive-order/detail/purchaseId/${purchaseOrderId}`,"form");
  },
}