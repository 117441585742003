import layout from "@/components/layout";

function load(comp){
  return ()=>import(`../../views/${comp}.vue`)
}
export default {
  path:'/supplier',
  component:layout,
  meta:{
    title:'供应商管理'
  },
  children:[
    {
      path:'bind/list',
      name:'SupplierBind',
      component: load('supplier/bind'),
      meta: {
        title: '关联供应商列表',
        module:'供应商管理',
        key:'SupplierBindManage',
      }
    }
  ]
}